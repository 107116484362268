@import './variables.scss';
$spacings: (
  xxs: var(--ion-padding-xxs),
  xs: var(--ion-padding-xs),
  s: var(--ion-padding-s),
  m: var(--ion-padding-md),
  l: var(--ion-padding-lg),
  xl: var(--ion-padding-xl),
  xxl: var(--ion-padding-xxl)
);
$directions: 'top', 'left', 'bottom', 'right';

@each $property in 'padding', 'margin' {
  @each $name, $spacing in $spacings {
    .#{$property}-#{$name} {
      #{$property}: $spacing !important;
    }
    .vertical-#{$property}-#{$name} {
      #{$property}-top: $spacing !important;
      #{$property}-bottom: $spacing !important;
    }
    .horizontal-#{$property}-#{$name} {
      #{$property}-left: $spacing !important;
      #{$property}-right: $spacing !important;
    }

    @each $direction in $directions {
      .#{$property}-#{$direction}-#{$name} {
        #{$property}-#{$direction}: $spacing !important;
      }
    }
  }

  @each $direction in $directions {
    .no-#{$property}-#{$direction} {
      #{$property}-#{$direction}: 0 !important;
    }
    .no-last-#{$property}-#{$direction}:last-of-type {
      #{$property}-#{$direction}: 0 !important;
    }
  }

  .no-#{$property} {
    #{$property}: 0 !important;
  }
  .no-last-#{$property}:last-of-type {
    #{$property}: 0 !important;
  }
  .no-vertical-#{$property} {
    #{$property}-top: 0 !important;
    #{$property}-bottom: 0 !important;
  }
  .no-horizontal-#{$property} {
    #{$property}-left: 0 !important;
    #{$property}-right: 0 !important;
  }
}

@each $direction in $directions {
  .margin-#{$direction}-auto {
    margin-#{$direction}: auto !important;
  }
}
