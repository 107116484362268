// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette(mat.$indigo-palette);
$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat.define-light-theme((color: (primary: $app-primary,
        accent: $app-accent,
        warn: $app-warn )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.core-theme($app-theme);

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-item-background: #ffffff;

  /** primary **/
  --ion-color-primary: #d81e05;
  --ion-color-primary-rgb: 215, 30, 5;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: rgb(168, 24, 5);
  --ion-color-primary-tint: #f11c00;

  /** secondary **/
  --ion-color-secondary: #be0f0f;
  --ion-color-secondary-rgb: 190, 15, 15;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #a30d0d;
  --ion-color-secondary-tint: #d10f0f;

  /** tertiary **/
  --ion-color-tertiary: #820404;
  --ion-color-tertiary-rgb: 216, 30, 5;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #640303;
  --ion-color-tertiary-tint: #990202;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** white **/
  --ion-color-white: #ffffff;
  --ion-color-white1: #ffffff;
  --ion-color-white2: #fff4ec;
  --ion-color-black: #000000;

  /** text **/
  --ion-color-primary-text: #333333;
  --ion-color-secondary-text: #666666;
  --ion-color-header-background: #4b4b4b;

  /** card **/
  --ion-color-unselected: #787878;
  --ion-color-selected: var(--ion-color-primary);
  --ion-color-card-border: #DADADA;

  --ion-color-orange: #ef9454;
  --ion-color-grey3: #eae9e9;

  /** steps **/
  --ion-color-steps-active: #8DB604;
  --ion-color-steps-inactive: #A5ADBB;
  --ion-color-steps-line: #EAE9E9;
  --ion-color-border: #bfbfbf;

  --ion-color-header-background: #333333;
  /** paddings **/
  --ion-padding: 48px;
  --ion-padding-xxs: 2px;
  --ion-padding-xs: 4px;
  --ion-padding-s: 8px;
  --ion-padding-md: 18px;
  --ion-padding-lg: 24px;
  --ion-padding-xl: 36px;
  --ion-padding-xxl: 48px;
  --ion-padding-item: 12px;
  //login
  --ion-padding-desktop-login: 48px;
  --ion-padding-tablet-login: 32px;
  --ion-padding-mobile-login: 24px;
  --ion-padding-tablet-quick-actions-height: 320px;
  --ion-padding-mobile-quick-actions-height: 416px;
  //page
  --ion-padding-desktop-page-horizontal: 48px;
  --ion-padding-desktop-page: var(--ion-padding-md) var(--ion-padding-desktop-page-horizontal);
  --ion-padding-tablet-page: var(--ion-padding-md);
  --ion-padding-mobile-page-horizontal-s: var(--ion-padding-s);
  --ion-padding-mobile-page-horizontal: var(--ion-padding-md);
  --ion-padding-mobile-page: 0 var(--ion-padding-mobile-page-horizontal) var(--ion-padding-s);

  /** sizes **/
  //header
  --ion-size-height: 72px;
  --ion-size-header-height: 60px;
  --ion-size-mobile-height: 60px;

  --ion-size-header-padding-top: var(--ion-padding-md);
  --ion-size-header-horizontal-padding: var(--ion-padding-xxl);
  --ion-size-header-padding-bottom: var(--ion-padding-md);

  --ion-size-tablet-header-padding-top: var(--ion-padding-md);
  --ion-size-tablet-header-horizontal-padding: var(--ion-padding-md);
  --ion-size-tablet-header-padding-bottom: var(--ion-padding-md);

  --ion-size-mobile-header-padding-top: var(--ion-padding-s);
  --ion-size-mobile-header-horizontal-padding: var(--ion-padding-s);
  --ion-size-mobile-header-padding-bottom: var(--ion-padding-s);

  //device sizes (based on the ionic grid)
  --ion-size-mobile-max: 767px;
  --ion-size-tablet-min: 768px;
  --ion-size-tablet-max: 1199px;
  --ion-size-desktop-min: 1200px;

  //components
  --ion-padding-component-vertical: var(--ion-padding-md);
  --ion-padding-component-horizontal: var(--ion-padding-md);
  --ion-margin-desktop-component-vertical: var(--ion-padding-md);
  --ion-margin-desktop-component-horizontal: var(--ion-padding-md);
  --ion-margin-tablet-component-vertical: var(--ion-padding-md);
  --ion-margin-mobile-component-vertical: var(--ion-padding-xs);
  --ion-margin-desktop-vertical: var(--ion-padding-md);

  //modals
  --ion-padding-modal-vertical: var(--ion-padding-lg);
  --ion-padding-modal-horizontal: var(--ion-padding-xl);
  --ion-padding-mobile-modal-vertical: var(--ion-padding-lg);
  --ion-padding-mobile-modal-horizontal: var(--ion-padding-md);

  //page
  --ion-padding-desktop-page-horizontal: var(--ion-padding-xxl);
  --ion-padding-desktop-page: var(--ion-padding-md) --ion-padding-desktop-page-horizontal;
  --ion-padding-tablet-page: var(--ion-padding-md);
  --ion-padding-mobile-page-horizontal-s: var(--ion-padding-s);
  --ion-padding-mobile-page-horizontal: var(--ion-padding-md);
  --ion-padding-mobile-page: 0 --ion-padding-mobile-page-horizontal var(--ion-padding-s);

  //header
  --ion-size-header-height: 72px;
  --ion-size-header-mobile-height: 60px;

  --ion-padding-desktop-header-top: var(--ion-padding-md);
  --ion-padding-desktop-header-horizontal: var(--ion-padding-xxl);
  --ion-padding-desktop-header-bottom: var(--ion-padding-md);

  --ion-padding-tablet-header-top: var(--ion-padding-md);
  --ion-padding-tablet-header-horizontal: var(--ion-padding-md);
  --ion-padding-tablet-header-bottom: var(--ion-padding-md);

  --ion-padding-mobile-header-top: var(--ion-padding-s);
  --ion-padding-mobile-header-horizontal: var(--ion-padding-s);
  --ion-padding-mobile-header-bottom: var(--ion-padding-s);

  //buttons
  --ion-size-button-xxxs: 48px;
  --ion-size-button-xxs: 96px;
  --ion-size-button-xs: 160px;
  --ion-size-button-s: 192px;
  --ion-size-button-m: 256px;
  --ion-size-button-l: 320px;
  --ion-size-button-xl: 480px;
  --ion-size-button-xxl: 768px;

  //border buttons
  --ion-size-border-radius: var(--ion-padding-lg);
  --ion-border: solid 0.5px;

  //border elements
  --ion-default-border: 1px solid;

  //textarea
  --ion-padding-textarea-vertical: var(--ion-padding-xs);
  --ion-padding-textarea-horizontal: var(--ion-padding-s);

  //icon sizes
  --ion-size-icon-xxs: 1.2rem;
  --ion-size-icon-xs: 1.4rem;
  --ion-size-icon-s: 1.8rem;
  --ion-size-icon-m: 2.2rem;
  --ion-size-icon-l: 2.4rem;
  --ion-size-icon-xl: 4rem;
  --ion-size-icon-xxl: 8rem;

  --ion-modal-width-l: 1024px;
  --ion-modal-width-m: 720px;

  //grid
  --grid-breakpoint-xs: 0;
  --grid-breakpoint-sm: 576px;
  --grid-breakpoint-md: 768px;
  --grid-breakpoint-lg: 992px;
  --grid-breakpoint-xl: 1200px;

  /** fonts **/
  //font weights
  --ion-font-weight-bold: 500;
  --ion-font-weight-normal: normal;
  //font sizes
  --ion-font-size-xxs: 8px;
  --ion-font-size-xs: 10px;
  --ion-font-size-s: 12px;
  --ion-font-size-m: 14px;
  --ion-font-size-m-l: 16px;
  --ion-font-size-l: 18px;
  --ion-font-size-xl: 20px;
  --ion-font-size-xxl: 22px;
  --ion-font-size-xxxl: 24px;
  --ion-badge-font-size: 9px;

  //font family
  --ion-font-family: "Roboto";

  //background sizes
  --ion-background-img: linear-gradient(to right, rgba(#000000, 7/10), rgba(#000000, 0));

  //animations
  --iontransition-xs: 150ms;
  --ion-transition-s: 200ms;
  --ion-transition-m: 500ms;

  --ion-grid-column-padding: 5px;
  --ion-grid-column-padding-xs: 5px;
  --ion-grid-column-padding-sm: 5px;
  --ion-grid-column-padding-md: 5px;
  --ion-grid-column-padding-lg: 5px;
  --ion-grid-column-padding-xl: 5px;

  //platform queries
  @mixin firefox-only {
    @supports (-moz-document: url-prefix()) {
      @content;
    }
  }

  @mixin ios-only {
    @supports (-webkit-overflow-scrolling: touch) {
      @content;
    }
  }

  @mixin not-ios {
    @supports not (-webkit-overflow-scrolling: touch) {
      @content;
    }
  }

  --ion-color-base: #EAE9E9;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

$thin-mobile-width: 450px;
$mobile-max: 767px;
$tablet-min: 768px;
$tablet-max: 1199px;
$desktop-min: 1200px;
$vertical-min: 550px;
$vertical-max: 551px;
$ultra-thin-width: 350px;