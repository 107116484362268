body,
p {
  font-size: var(--ion-font-size-s);
}

a,
button {
  font-size: var(--ion-font-size-s) !important;
}

h1 {
  font-size: var(--ion-font-size-xxl) !important;
}
h2 {
  font-size: var(--ion-font-size-xl) !important;
}
h3 {
  font-size: var(--ion-font-size-l) !important;
}
h4 {
  font-size: var(--ion-font-size-m-l) !important;
}
h5 {
  font-size: var(--ion-font-size-s) !important;
}
h1, h2, h3, h4, h5 {
  margin: 0;
}

.fmm-body1 {
  font-size: var(--ion-font-size-m) !important;
}

.fmm-body2 {
  font-size: var(--ion-font-size-s) !important;
}

$font-sizes: (
  xxs: var(--ion-font-size-xxs),
  xs: var(--ion-font-size-xs),
  s: var(--ion-font-size-s),
  m: var(--ion-font-size-m),
  l: var(--ion-font-size-l),
  xl: var(--ion-font-size-xl),
  xxl: var(--ion-font-size-xxl)
);
@each $name, $size in $font-sizes {
  .font-#{$name} {
    font-size: #{$size} !important;
  }
}
