/* main */
@use "sass:math";
@import './variables.scss';


ion-popover {
  &.popoverHeaderProfile {
    .popover-content {
      left: auto !important;
      right: 0;
    }
  }
}

ion-content {
  >.scroll-content {
    overflow-y: auto;
  }
}

.ios ion-nav>.ion-page>ion-content.statusbar-padding:first-child .scroll-content,
.ios ion-nav>.ion-page>ion-header>ion-content.statusbar-padding:first-child .scroll-content,
.ios ion-tab>.ion-page>ion-header>ion-content.statusbar-padding:first-child .scroll-content,
.ios ion-tabs>.ion-page.tab-subpage>ion-header>ion-content.statusbar-padding:first-child .scroll-content,
.ios ion-menu>.menu-inner>ion-content.statusbar-padding:first-child .scroll-content,
.ios ion-menu>.menu-inner>ion-header>ion-content.statusbar-padding:first-child .scroll-content {
  padding-top: 0 !important;
}

.display-block {
  display: block;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.relative-position {
  position: relative;
}

.vertically-centered {
  vertical-align: middle;
}

.vertically-centered-content {
  >* {
    vertical-align: middle;
  }
}

.border-circle {
  border-radius: 50%;
  overflow: hidden;
}

.bottom-border {

  &:not(:last-child),
  &.last-border-too {
    border-bottom: 1px solid var(--ion-color-grey3);

    &:not(.border-only) {
      padding-bottom: var(--ion-padding-s);
      margin-bottom: var(--ion-padding-md);
    }
  }
}

ion-item {
  &.no-item-border {
    border: 0 !important;
  }

  &[no-label] {
    ion-label {
      display: none;
    }

    ion-select {
      max-width: 100% !important;
      width: 100%;
    }
  }
}

.font-16-h3 {
  font-size: 16px !important;
}

.actions-wrapper {
  ion-col:not(:first-child):last-child {
    margin-left: auto
  }

  button {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 3.4rem;
    contain: initial;
    position: static;

    &:hover {
      padding-top: 10px;
      padding-bottom: 10px;
      height: 3.4rem;
      contain: initial;
      position: static;
    }
  }
}

.clickable {
  cursor: pointer;
}

.non-clickable {
  cursor: unset;
}

.border-top {
  border-top: 1px solid var(--ion-color-grey3);
}

.click-through {
  pointer-events: none;
}

.animated {
  transition: var(--ion-transition-s);

  &.medium {
    transition: var(--ion-transition-m);
  }
}

.fade {
  @extend .animated;

  &.fade-out {
    opacity: 0;
  }
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: bold !important;
}

.normal-weight {
  font-weight: normal !important;
}

.auto-margin {
  margin: auto;
  width: 100%;
}

.auto-width {

  &,
  &.input>* {
    width: auto !important;
  }
}

a {
  &[href*='tel:'] {
    white-space: nowrap;
  }
}

.auto-height {

  &,
  &.input>* {
    height: auto !important;
  }
}

.rating-container {
  background-color: #fff8d255;
  padding: 24px 0;
}

.alert {
  $base-padding: var(--ion-padding-s);
  padding: $base-padding  !important;

  ion-icon {
    margin: 0 !important;

    &[item-end] {
      margin-left: var(--ion-padding-s) !important;
    }
  }

  .item-inner {
    border: none !important;

    ion-label {
      margin: 0 !important;
    }
  }


  &.warning {
    background: var(--ion-color-white2);

    .subtitle-warning {
      margin-bottom: var(--ion-padding-s);
      margin-top: var(--ion-padding-xxs);
    }

    &,
    ion-icon {
      color: var(--ion-color-orange);
    }
  }

  &.info {
    background: var(--ion-color-light-blue);

    &,
    ion-icon {
      color: var(--ion-color-blue);
    }
  }

  &.success {
    background: var(--ion-color-white1);

    &,
    ion-icon {
      color: var(--ion-color-green);
    }
  }
}

.light {

  &,
  & * {
    color: var(--ion-color-white1) !important;
  }
}

.check-green {
  ion-icon[aria-label="mapfre check"] {
    color: var(--ion-color-green);
  }

  ion-icon[name="mapfre-checkbox-on"],
  ion-icon[aria-label="mapfre checkbox-on"] {
    color: var(--ion-color-green);
  }
}

.text-area:not(.no-border) {
  .item-inner {
    border-bottom: none !important;
  }

  textarea {
    resize: none;
    min-height: 115px;
  }

  ion-textarea {
    border: 1px solid var(--ion-color-grey2);
    border-radius: 4px;
    padding-left: 2px;
    padding-right: 4px;
    min-height: 131px;
  }

  ion-label {
    padding-left: 10px;
  }
}

.error-red {
  ion-icon[aria-label="cross error"] {
    color: var(--ion-color-primary);
  }

  ion-icon {

    &.ion-ios-mapfre-cross-error,
    &.ion-md-mapfre-cross-error {
      color: var(--ion-color-primary);
    }
  }
}

.no-button-card {
  padding-bottom: 42px;

  /* tablet */
  @media (min-width: $tablet-min) and (max-width: $tablet-max) {
    padding-bottom: 24px;
  }

  /* mobile */
  @media (max-width: $mobile-max) {
    padding-bottom: 24px;
  }
}

.drop-shadow {
  // Same values used by ionic, for consistency
  box-shadow: 0 2px 2px 0 rgba(black, 0.14), 0 3px 1px -2px rgba(black, 0.2), 0 1px 5px 0 rgba(black, 0.12);
}

.vertically-center-direct-children>* {
  vertical-align: middle !important;
}

.values-list {
  &.unlabeled-form {
    .fmm-body1 {
      line-height: var(--ion-font-size-size-m);
      display: block;
    }

    .input-col {
      $spacing: 5px;

      &:not([no-padding]) {
        padding: 0 $spacing;
      }

      .item-input {
        min-height: 0;

        .text-input {
          margin: $spacing 0;
        }
      }
    }
  }

  ion-row {
    &:not([no-padding]) {
      padding: var(--ion-padding-s) 0;
    }

    ion-col {
      padding: 0;
    }
  }
}

.locked-form {
  ion-col:nth-child(2) {
    &:not([no-margin]) {
      margin-bottom: 0;
    }
  }
}

.full-width {
  width: 100% !important;
}

.full-height {
  height: 100% !important;
}

.margin-auto {
  margin: auto;
}

.keep-line-breaks {
  white-space: pre;
}

.no-background {
  background: none;
}

/* html elements */

a {
  text-decoration: none;
}

strong {

  &,
  &.fmm-body1,
  &.fmm-body2 {
    font-weight: bold !important;
  }
}

ion-select {
  .select-placeholder {
    color: var(--ion-color-grey2) !important;
  }
}

/* ionic components */

ion-app {
  background: var(--ion-color-grey3);

  @mixin ion-icon-size($size) {
    min-height: $size  !important;
    min-width: $size  !important;
    font-size: $size  !important;
  }

  ion-toggle {
    &.inline-block {
      padding: 16px 8px 16px 0;
    }
  }

  ion-label {
    white-space: normal !important;
  }

  ion-card {
    &.no-card {
      background: none;
      box-shadow: none !important;
      border: 0;
      margin: 0;

      ion-card-header,
      ion-card-content {
        padding: 0 !important;
      }
    }

    ion-card-header,
    ion-card-content {
      &:not([no-padding]) {
        padding: var(--ion-padding-component-vertical) var(--ion-padding-component-vertical);

        &.icon-card-content {
          padding-top: 42px;

          /* tablet */
          @media (min-width: $tablet-min) and (max-width: $tablet-max) {
            padding-top: 24px;
          }

          /* mobile */
          @media (max-width: $mobile-max) {
            padding-top: 24px;
          }
        }
      }
    }

    ion-card-header {
      &:not(.no-border) {
        >*:last-child {
          border-bottom: 1px solid var(--ion-color-grey3);
          padding-bottom: 0 !important;

          &:not(.item-block) {
            padding-bottom: var(--ion-padding-component-vertical);
          }

          &.item-block .item-inner {
            padding-bottom: var(--ion-padding-component-vertical) !important;
          }
        }

        >ion-item>ion-icon {
          padding-bottom: var(--ion-padding-component-vertical);
        }

        ion-label {
          margin: 0 !important;
        }
      }

      >ion-item {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      ion-item {
        ion-label {
          margin: 0 !important;

          h3 {
            margin: 0 !important;
          }

          h4 {
            margin: var(--ion-padding-xxs) 0 0 !important;
          }
        }

        ion-icon[name|="mapfre-arrow"] {
          font-size: var(--ion-size-icon-s) !important;
          min-height: var(--ion-size-icon-s) !important;
          min-width: var(--ion-size-icon-s) !important;
        }
      }

      .card-header-select {
        display: flex;

        ion-select {
          $chevron-size: 6px;
          padding-left: math.div($chevron-size, 2) !important;
          padding-right: math.div($chevron-size, 2) !important;
          margin: -var(--ion-padding-lg)/2 0;

          .select-text {
            text-align: right;
            max-width: 100%;
            flex: 1;
          }
        }

        /* mobile */
        @media (max-width: $mobile-max) {
          .select-label {
            display: none;
          }

          ion-select {
            >* {
              display: none;
            }

            &:before {
              font-family: 'Ionicons';
              content: '\f182';
              cursor: pointer;
              margin-left: auto;
              font-size: var(--ion-font-size-size-l);
            }
          }
        }
      }
    }

    .sub-title {
      background: rgba(234, 233, 233, 0.7) !important;
      min-height: var(--ion-padding-xl) !important;
      padding-left: var(--ion-padding-md) !important;
      padding-right: var(--ion-padding-md) !important;
      display: flex;
      align-content: center;

      &:not([class*="padding"]) {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }

      .label-ios {
        margin: 0;
      }

      * {
        color: var(--ion-color-primary-text) !important;
      }
    }

    .validation {
      ion-item {
        @extend .alert;
        @extend .warning;
        @extend .drop-shadow;
      }
    }
  }

  >*:not(ion-modal) {
    ion-card {
      width: auto !important;
      border-radius: 5px !important;
      box-shadow: 0 3.5px 4.7px 0.3px rgba(180, 180, 180, 0.24), 0 1.5px 6.4px 0.1px rgba(180, 180, 180, 0.16) !important;

      &:not([no-margin]):not(.no-vertical-margin) {

        /* desktop */
        @media (min-width: $desktop-min) {
          margin-top: var(--ion-margin-desktop-vertical) !important;
          margin-bottom: var(--ion-margin-desktop-vertical) !important;
        }

        /* tablet */
        @media (min-width: $tablet-min) and (max-width: $tablet-max) {
          margin-top: var(--ion-margin-tablet-component-vertical) !important;
          margin-bottom: var(--ion-margin-tablet-component-vertical) !important;
        }

        /* mobile */
        @media (max-width: $mobile-max) {
          margin-top: var(--ion-margin-mobile-component-vertical) !important;
          margin-bottom: var(--ion-margin-mobile-component-vertical) !important;
        }

      }
    }

  }

  ion-item {
    font-size: var(--ion-font-size-size-m) !important;

    &,
    .item-inner {
      min-height: 0 !important;
    }

    &.no-border {
      .item-inner {
        border: 0 !important;
      }
    }

    &.no-style {
      background: transparent;

      .item-inner {
        border: 0 !important;

        ion-label {
          @extend .no-vertical-margin;
        }
      }
    }

    [item-top] {
      align-self: flex-start;
    }

    [item-bottom] {
      align-self: flex-end;
    }

    ion-icon {

      &[item-left],
      &[item-start],
      &[item-right],
      &[item-end] {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }

      &[item-left],
      &[item-start] {

        +.item-inner,
        +.item-input {
          margin-left: var(--ion-padding-s) !important;
        }
      }

      &[item-right],
      &[item-end] {

        +.item-inner,
        +.item-input {
          margin-right: var(--ion-padding-s) !important;
        }
      }

      &.with-badge {
        position: relative;

        ion-badge {
          $size: var(--ion-font-size-size-m);
          $padding: 0;

          font-family: var(--ion-font-size-family);
          font-size: var(--ion-badge-font-size);
          line-height: $size;
          min-width: $size;
          min-height: $size;
          padding: $padding;
          margin: -var(--ion-padding-xxs);
          border-radius: 50%;

          position: absolute;
          right: 0;
          top: 0;
        }
      }

    }

    ion-label {
      margin: var(--ion-padding-s) 0 !important;
      white-space: normal !important;
    }

    .item-inner {
      padding-right: 0 !important;
    }

    &.no-label-margin {
      ion-label {
        @extend .no-vertical-margin;
      }
    }

    &[no-padding] {
      padding: 0 !important;
    }

    [item-end] {
      margin-right: 0;
    }

    ion-icon {
      color: var(--ion-color-primary-text);
      text-align: center !important;
    }

    &.item-radio {
      &.item {
        padding: var(--ion-padding-xs) 0 !important;
      }

      &.item-ios {
        .label-ios {
          margin: 0 !important;
        }
      }
    }
  }

  ion-icon {
    &:not(.custom-size) {
      @include ion-icon-size(var(--ion-size-icon-m));
    }

    &[extra-small] {
      @include ion-icon-size(var(--ion-size-icon-xxs));
    }

    &[small] {
      @include ion-icon-size(var(--ion-size-icon-xs));
    }

    &[medium-small] {
      @include ion-icon-size(var(--ion-size-icon-s));
    }

    &[medium] {
      @include ion-icon-size(var(--ion-size-icon-m));
    }

    &[large] {
      @include ion-icon-size(var(--ion-size-icon-l));
    }

    &[extra-large] {
      @include ion-icon-size(3.2rem);
    }

    &[medium-huge] {
      @include ion-icon-size(var(--ion-size-icon-xl));
    }

    &[huge] {
      @include ion-icon-size(9.6rem);
    }

    &[extra-huge] {
      @include ion-icon-size(16.5rem);
    }

    &[no-margin] {
      margin: 0 !important;
    }

    &.icon-m {
      font-size: var(--ion-padding-md) !important;
    }

    &.selection {
      cursor: pointer;

      &[aria-label="mapfre check"],
      &[aria-label="mapfre checkbox on"] {
        color: var(--ion-color-green);
      }
    }

  }

  ion-grid {
    &[no-padding] {
      >ion-row {
        >ion-col {
          padding: 0 !important;
        }
      }
    }

    &.no-vertical-col-padding {
      ion-col {
        @extend .no-vertical-padding;
      }
    }

    &.no-horizontal-col-padding {
      ion-col {
        @extend .no-horizontal-padding;
      }
    }

    ion-row {
      &.center-vertically {
        align-items: center;
      }
    }

    $spacings: (
      xxs: var(--ion-padding-xxs),
      xs: var(--ion-padding-xs),
      s: var(--ion-padding-s),
      m: var(--ion-padding-md),
      l: var(--ion-padding-lg),
      xl: var(--ion-padding-xl),
      xxl: var(--ion-padding-xxl)
    );

  @each $name,
  $spacing in $spacings {
    .col-padding-#{$name} {
      ion-col {
        padding: $spacing;
      }
    }
  }
}

ion-textarea {
  &.bordered {
    border: 1px solid var(--ion-color-grey2);
    border-radius: var(--ion-padding-xxs);
  }

  textarea {
    margin: var(--ion-padding-textarea-vertical) var(--ion-padding-textarea-horizontal) !important;
  }
}

ion-searchbar {
  &.round {
    input {
      border-radius: var(--ion-padding-lg) !important;
    }
  }

  &.icon-to-left {
    .searchbar-search-icon {
      left: 16px;
      right: initial;
    }
  }

  &.keep-icon {
    &.searchbar-has-value.searchbar-has-focus .searchbar-search-icon {
      display: block;
    }
  }
}

.labeled-textarea {
  ion-label[floating] {
    padding: 0 var(--ion-padding-textarea-horizontal);
    margin: var(--ion-padding-textarea-vertical) 0;
  }
}

ion-item ion-radio,
ion-item ion-checkbox {
  margin: 0 8px 0 0 !important;
}

ion-list {
  &.no-item-padding {
    ion-item {
      padding: 0 !important;
    }
  }

  &[radio-group] {
    ion-label {
      margin-left: 10px !important;
    }
  }
}

ion-radio {
  &.radio-ios {
    $unselected-color: var(--ion-color-unselected);
    $selected-color: var(--ion-color-green);

    .radio-icon {
      $size: 16px;
      width: $size  !important;
      height: $size  !important;
      border-radius: 50%;
      box-sizing: border-box;
      border: 2px solid var(--ion-color-unselected);

      &.radio-checked {
        border-color: var(--ion-color-selected);

        .radio-inner {
          transform: none;
        }
      }

      .radio-inner {
        $size: 8px;
        width: $size  !important;
        height: $size  !important;
        background-color: var(--ion-color-selected);
        border-color: var(--ion-color-selected);
        left: 2px !important;
        top: 2px !important;
        border-radius: 50%;
        position: absolute;
        transition: transform 280ms cubic-bezier(0.4, 0, 0.2, 1) !important;
        transform: scale3d(0, 0, 0);
      }
    }
  }
}

ion-checkbox {
  &.checkbox-ios {
    $unselected-color: var(--ion-color-unselected);
    $selected-color: var(--ion-color-green);

    .checkbox-icon {
      $size: 20px;
      width: $size;
      height: $size;
      border-radius: 0;
      position: relative;
      border: 1px solid $unselected-color;
      background-color: var(--ion-color-white1);
      transition-duration: 280ms;
      transition-property: background;
      transition: transform 280ms cubic-bezier(0.4, 0, 0.2, 1) !important;

      &.checkbox-checked {
        border-color: $selected-color;
        background-color: var(--ion-color-white1);

        .checkbox-inner {
          left: 7px;
          top: 3px;
          position: absolute;
          width: 5px;
          height: 10px;
          transform: rotate(45deg);
          border: 0 solid $selected-color;
          border-right-width: 2px;
          border-bottom-width: 2px;
        }
      }
    }
  }
}

}

.header-background {
  position: absolute;
  height: 300px;
  width: 100%;
  background: --ion-background-img, url("../assets/images/non-scalable/background/steps/83-2021-F.png");
  background-position: center;
}

.header-background-login {
  position: absolute;
  height: 300px;
  width: 100%;
  background: --ion-background-img, url("../assets/images/non-scalable/background/login/imagen_fondo_login.jpg");
  background-position: center;
}

.upper-header {

  /* desktop */
  @media (min-width: $desktop-min) {
    padding-top: var(--ion-padding-md);
    padding-bottom: var(--ion-desktop-header-padding-bottom);
  }

  /* tablet */
  @media (min-width: $tablet-min) and (max-width: $tablet-max) {
    padding-top: var(--ion-padding-md);
    padding-bottom: var(--ion-tablet-header-padding-bottom);
  }

  /* mobile */
  @media (max-width: $mobile-max) {
    padding-top: var(--ion-padding-lg);
    padding-bottom: var(--ion-mobile-header-padding-bottom);
  }

  >p {
    margin: 6px 0 0;
  }

}

.tooltip-wrapper {
  display: flex;

  sup {
    margin-left: var(--ion-padding-xxs);
    top: 0;
  }
}

.no-button-margin {
  button {
    margin: 0 !important;
  }
}

ion-grid.generic-pagination {
  padding: var(--ion-padding-md) 0 0;

  ion-row,
  ion-col {
    padding: 0;
  }

  button {
    margin: 0;
  }
}

ion-grid.generic-buttons {
  padding: var(--ion-padding-md) 0 0;

  ion-row {
    padding: 0;
  }

  ion-col {
    padding-top: 0;
    padding-bottom: 0;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }

  button {
    margin: 0;
  }
}

.columns-layout {
  ion-col.main {

    ion-grid.generic-pagination,
    ion-grid.generic-buttons,
    .right-padded {

      /* desktop */
      @media (min-width: $desktop-min) {
        padding-right: var(--ion-padding-md)/2 !important;
      }

    }

  }
}

.hide-element {
  display: none;
}

/* desktop */
@media (min-width: $desktop-min) {
  .hide-on-desktop {
    display: none;
  }

  .tablet-only {
    display: none;
  }

  .mobile-only {
    display: none;
  }
}

/* tablet */
@media (min-width: $tablet-min) and (max-width: $tablet-max) {
  .desktop-only {
    display: none;
  }

  .hide-on-tablet {
    display: none !important;
  }

  .mobile-only {
    display: none;
  }

  .header-background {
    position: absolute;
    height: 260px;
    width: 100%;
    background: --ion-background-img, url("../assets/images/non-scalable/background/steps/83-2021-F.png");
    background-position: center;

    &.gray-unless-desktop {
      // height: 189px; the requested height is insufficient. Fran has agreed to leave the height limitation off for now
      background-image: none !important;
      background-color: var(--ion-color-header-background) !important;
    }
  }
}

/* mobile */
@media (max-width: $mobile-max) {
  .desktop-only {
    display: none;
  }

  .tablet-only {
    display: none;
  }

  .hide-on-mobile {
    display: none !important;
  }

  .header-background {
    position: absolute;
    height: 400px;
    width: 100%;
    background: --ion-background-img, url("../assets/images/non-scalable/background/steps/83-2021-F.png");
    background-position: center;

    &.gray-unless-desktop {
      //height: 145px; the requested height is insufficient. Fran has agreed to leave the height limitation off for now
      background-image: none !important;
      background-color: var(--ion-color-header-background) !important;
    }
  }

  .columns-layout {
    ion-col.main {
      padding: 0px !important;
    }
  }
}

.desktop-steps {
  padding: var(--ion-padding-xs) 0 !important;

  ion-item {
    padding: var(--ion-padding-s) var(--ion-padding-md) !important;
    min-height: 56px !important; // Exact height for title + subtitle entries, so items don't resize when the subtitle becomes available

    ion-label {
      @extend .no-vertical-margin;
    }

    ion-icon {
      position: relative;

      &>span {
        font-family: var(--ion-font-size-family);
        text-align: center;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        margin: 10px 0;
      }
    }

    @mixin setupStepColors($icon, $text) {
      ion-icon {
        color: $icon;

        * {
          color: $text;
        }
      }
    }

    @include setupStepColors(var(--ion-color-grey2), var(--ion-color-white1));

    &.visitable {
      @include setupStepColors(var(--ion-color-green), var(--ion-color-white1));
    }

    &.active {
      @include setupStepColors(var(--ion-color-green), var(--ion-color-secondary-text));
    }
  }

}


@mixin flex-box() {
  display: flex;

  &.centered {
    align-items: center;
    height: 100%;
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &>* {
    flex: 1;

    &.flex-two {
      flex: 2;
    }

    &.flex-three {
      flex: 3;
    }
  }
}

.flex {
  display: flex;

  &.align-start {
    align-items: flex-start;
  }

  &.center-contents {
    align-items: center;
  }

  &.justify-center-contents {
    justify-content: center;
  }
}



.flex-box {
  @include flex-box();
}

/* desktop */
@media (min-width: $desktop-min) {
  .desktop-flex-box {
    @include flex-box();
  }
}

/* tablet */
@media (min-width: $tablet-min) and (max-width: $tablet-max) {
  .tablet-flex-box {
    @include flex-box();
  }
}

.scroll-content {
  padding-bottom: 0 !important;
}

ion-input.ng-invalid {
  border-color: var(--ion-color-primary) !important;
}

.personalization-rules {
  span {
    $size: var(--ion-size-icon-l);
    height: $size;
    width: $size;
    background-color: var(--ion-color-grey3);
    border-radius: 50%;
    cursor: pointer;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:not(:first-of-type) {
      margin-left: var(--ion-padding-md);
    }

    &.selected {
      background-color: var(--ion-color-primary);
      color: var(--ion-color-white1);
    }
  }
}

.overflow-auto {
  overflow: auto;
}

.custom-scroll-bar {
  scrollbar-color: var(--ion-color-light-shade);
  scrollbar-width: thin;
}

.custom-scroll-bar::-webkit-scrollbar-track {
  -webkit-box-shadow: white;
  background-color: white;
  border-radius: 10px;
}

.custom-scroll-bar::-webkit-scrollbar {
  width: 10px;
  background-color: white;
}

.custom-scroll-bar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--ion-color-light-shade);
}