// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import './theme/typography.scss';
@import './theme/forms.scss';
@import './theme/spacing.scss';
@import './theme/generic.scss';

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  text-align: left;
  display: flex;
  margin-top: 1rem;

  span {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: var(--ion-padding-md);
  }
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

ion-footer {
  position: sticky;
  top: 100vh;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.form-font-size {
  font-size: 16px !important;
}

.required-text {
  font-size: 12px;
}

.required-color {
  color: var(--ion-color-primary);
}

.content-wrapper {
  width: 100%;
}


ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}