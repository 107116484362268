@use "sass:math";
@import './variables.scss';

.required {
  ion-label {
    &:after {
      content: '*';
    }
  }

  &:not(ion-item) {
    &:after {
      content: '*';
    }
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input {
  background: transparent;
}

button.button-full:not(.swiper-pagination-bullet):not(.ng-trigger-stepState):not(.custom-size):not(.alert-radio):not(.fab):not(.alert-button):not(.bar-button) {
  $font-size: var(--ion-font-size-s);
  font-size: $font-size;
  line-height: calc($font-size * 1.5);
  padding: var(--ion-padding-s) var(--ion-padding-md) !important;
  height: 42px;

  span {
    align-content: center;
    display: flex;
  }

  &.secondary {
    background-color: var(--ion-color-white1);

    &:hover {
      color: var(--ion-color-secondary) !important;
    }

    &:not(:hover) {
      color: var(--ion-color-primary) !important;
    }
  }

  >span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

ion-input {
  color: var(--ion-color-primary-text);

  &.no-horizontal-margin {
    input {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}

ion-select {
  &[full] {
    width: 100%;
    max-width: 100%;

    .item-cover {
      padding: 0;
    }
  }

  &.form-select {
    border-bottom: 1px solid var(--ion-color-grey2);
    padding: 13px 6px;

    .select-text {
      font-size: var(--ion-font-size-m);

      &.select-placeholder {
        color: var(--ion-color-grey1) !important;
      }
    }
  }
}

.bordered-selects {
  ion-select {
    border-bottom: 1px solid var(--ion-color-grey2);
    margin-bottom: 1px; // patch for Edge border issues
  }
}

.padded-form-group {
  ion-row {
    &:not(:last-child) {
      margin-bottom: var(--ion-padding-s);
    }

    ion-col {
      padding: 0;

      ion-item {
        padding: 0 !important;
      }
    }
  }
}

.inline-fake-checkbox {
  display: inline-block;

  &>* {
    vertical-align: middle;
    display: inline-block;
  }

  &>ion-icon {
    margin-right: var(--ion-padding-md);
  }
}

.item-with-outer-icon {
  $reserved-icon-space: 32px; // ionic input height
  display: flex;
  align-items: flex-end;

  >* {
    display: inline-block;
    vertical-align: bottom;
  }

  >ion-item {
    width: calc(100% - #{$reserved-icon-space});
  }

  >ion-icon {
    width: $reserved-icon-space;
    height: $reserved-icon-space;
    color: var(--ion-color-primary-text);
    display: flex;
    align-items: center;
    text-align: center;

    &::before {
      flex: 1;
    }
  }
}

.weirdly-padded-form {
  padding: 0;
  margin: 0;

  /* desktop */
  @media (min-width: $desktop-min) {
    width: math.div(2, 3) * 100%;
  }

  /* tablet */
  @media (min-width: $tablet-min) and (max-width: $tablet-max) {
    width: math.div(17, 20) * 100%;
  }

  /* mobile */
  @media (max-width: $mobile-max) {
    width: 100%;
  }
}

@mixin setupForm() {
  .padded-form-group {
    ion-col {
      $padding: calc(var(--ion-padding-lg) / 2);

      &:first-child {
        ion-item {
          padding-right: #{$padding}  !important;

          &.item-input.ng-valid.input-has-value .item-inner,
          &.item-datetime.ng-valid.input-has-value .item-inner {
            &:after {
              right: #{$padding} + 5px;
            }
          }
        }
      }

      &:not(:first-child):last-child {
        ion-item {
          padding-left: $padding  !important;
        }
      }
    }
  }

  .steps-pagination {
    padding-right: 20px !important;
  }
}

/* desktop */
@media (min-width: $desktop-min) {
  @include setupForm();
}

/* tablet */
@media (min-width: $tablet-min) and (max-width: $tablet-max) {
  @include setupForm();
}